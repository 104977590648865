import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from '../../../../app/views/apps/users/users.services';
import { AuthenticationService } from '@app/shared/services/authentication.service';

@Component({
    selector: 'nav-profile',
    templateUrl: './nav-profile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.header-nav-item]': 'true'
    }
})
export class NavProfileComponent implements OnInit {

    user: any

    constructor(
        private auth: AuthenticationService,
        private cdr: ChangeDetectorRef,
        private userService: UsersService,
        private router: Router
    ) {
        this.getUser()
     }

    profileMenuList = [
        // {
        //     path: '/apps/users',
        //     icon: 'feather icon-user',
        //     item: 'Perfil'
        // },
        {
            path: '',
            icon: 'feather icon-power',
            item: 'Cerrar sesión'
        }
    ]

    async getUser(){
        const data = JSON.parse(localStorage.getItem('currentUser'))
        try{
            const {user}:any = await this.userService.getUser(data.user.userUUID).toPromise()
            this.user = user
            this.cdr.markForCheck();
        }catch(ex){

        }
     }

    ngOnInit(): void { }

    call(type){
        if(type == 'Cerrar sesión')
            this.auth.logout()
        else
            this.router.navigate(['/apps', 'users', this.user.uuid])
    }

}
