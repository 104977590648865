import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(private http: HttpClient) {}

  getUsers(companyUUID?:string) {
    return this.http.get<Array<any>>(`${environment.apiUrl}users?${companyUUID? `companyUUID=${companyUUID}` : ''}`);
  }

  getUser(uuid, companyUUID?:string) {
    return this.http.get(`${environment.apiUrl}users/${uuid}?${companyUUID? `companyUUID=${companyUUID}` : ''}`)
  }

  postUser(body){
    return this.http.post(`${environment.apiUrl}users`, body)
  }

  putUser(uuid, body){
    return this.http.put(`${environment.apiUrl}users/${uuid}`, body)
  }

  deleteUser(uuid){
    return this.http.delete(`${environment.apiUrl}users/${uuid}`)
  }
}